import React from 'react';
import { Grid } from '@material-ui/core';
import { Images } from 'assets/index';
import VGSMuiTextField from '../../link2pay/components/VGSMuiTextField';
import { Colors, } from 'assets/index';
import Feedback from 'react-bootstrap/Feedback';
import styled from 'styled-components';

const StyledFormError = styled(Feedback)`
    color: ${Colors.redDanger};
    display: block;
`;

type Props = {
    vgsForm: Object;
    error: Object;
};


const PaymentMethodRequestCardV = ({ formik, vgsForm, error, vgsErrors, subtitle }: Props) => {
    return (
        <Grid container item spacing={2}>
            <Grid item xs={12}>
                <div className={subtitle}>Credit Card Information</div>
            </Grid>
            {!vgsForm ?
                <div>Loading...</div>
                : <Grid container item spacing={2}>
                    <Grid item xs={12}>
                        <VGSMuiTextField
                            vgsForm={vgsForm}
                            id='cardholder_name'
                            label='Cardholder Name'
                            required></VGSMuiTextField>
                        {
                            vgsErrors?.cardholder_name && (
                                <StyledFormError type='invalid'>
                                    {`Cardholder Name ${vgsErrors.cardholder_name.errorMessages[0]}`}
                                </StyledFormError>
                            )
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <VGSMuiTextField
                            vgsForm={vgsForm}
                            id='card_number'
                            label='Card Number'
                            type='card-number'
                            successColor='#4F8A10'
                            errorColor='#D8000C'
                            showCardIcon
                            required></VGSMuiTextField>
                        {
                            vgsErrors?.card_number && (
                                <StyledFormError type='invalid'>
                                    {`Card Number ${vgsErrors.card_number.errorMessages[0]}`}
                                </StyledFormError>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <VGSMuiTextField
                            vgsForm={vgsForm}
                            id='exp_date'
                            label='Exp'
                            type='card-expiration-date'
                            serializers={[{ name: 'replace', options: { old: ' ', new: '' } }]}
                            yearLength={2}
                            placeholder='MM/YY'
                            required></VGSMuiTextField>
                        {
                            vgsErrors?.exp_date && (
                                <StyledFormError type='invalid'>
                                    {`Expiration Date ${vgsErrors.exp_date.errorMessages[0]}`}
                                </StyledFormError>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <VGSMuiTextField
                            vgsForm={vgsForm}
                            id='cvv_code'
                            label='CVV'
                            type='card-security-code'
                            required></VGSMuiTextField>
                        {
                            vgsErrors?.exp_date && (
                                <StyledFormError type='invalid'>
                                    {`CVV Code ${vgsErrors.exp_date.errorMessages[0]}`}
                                </StyledFormError>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <VGSMuiTextField
                            vgsForm={vgsForm}
                            id='zip_code'
                            label='Zip Code'
                            extraValidations={['postal_code/us,au,ca']}
                            maxLength={10}
                            required></VGSMuiTextField>
                        {
                            vgsErrors?.exp_date && (
                                <StyledFormError type='invalid'>
                                    {`Zip Code ${vgsErrors.exp_date.errorMessages[0]}`}
                                </StyledFormError>
                            )
                        }
                    </Grid>
                </Grid>
            }

            <Grid item xs={12}>
                <img src={Images.settings.ccImages} width='300' />
            </Grid>
        </Grid>
    );
};

export default PaymentMethodRequestCardV;